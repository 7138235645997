<template>
  <v-card>
    <v-card-title>
      <h3 class="card-label">Mobil İstatistikler</h3>
    </v-card-title>
    <v-card-text>
      <iframe
        v-if="src"
        :src="src"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        width="100%"
        height="100%"
        loading="lazy"
        sandbox="allow-forms allow-presentation allow-scripts allow-same-origin"
        style="min-height: 80vh"
      ></iframe>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["matomoReadonlyAuthToken"]),
    src() {
      const url = new URL("https://abakus.robosay.com.tr/index.php");
      url.searchParams.set("token_auth", this.matomoReadonlyAuthToken);
      url.searchParams.set("module", "Widgetize");
      url.searchParams.set("action", "iframe");
      url.searchParams.set("moduleToWidgetize", "Dashboard");
      url.searchParams.set("actionToWidgetize", "index");
      url.searchParams.set("idSite", "2");
      url.searchParams.set("period", "week");
      url.searchParams.set("date", "today");

      return url.toString();
    },
  },
};
</script>
